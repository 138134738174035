import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';
import { Layout, Container } from 'layout';

import ProductsFinder from 'components/ProductsFinder';
import GatsbyImage from 'common/GatsbyImage/GatsbyImage';
import IconCustom from 'common/IconCustom';

import { INotFound404Page } from './models';
import './404.scss';
import './Shadow404.scss';

const NotFound404Page: FC<INotFound404Page> = ({ data: { pageData }, pageContext: { lang } }) => {
  const {
    defaultCompositions,
    seo,
    image404,
    mobileImage404,
    title,
    subTitle,
    startQuiz,
    goBackButton,
  } = pageData;

  return (
    <Layout defaultCompositions={defaultCompositions} seo={seo}>
      <Container>
        <div className="not-found-404-page" data-test="NotFound404Page">
          <GatsbyImage image={{ ...image404, mobileImage: mobileImage404?.gatsbyImage }} />
          {title ? <h1 className="not-found-404-page__title">{title}</h1> : null}
          {subTitle ? <h2 className="not-found-404-page__sub-title">{subTitle}</h2> : null}

          {startQuiz ? (
            <ProductsFinder
              startQuiz={startQuiz}
              productQuizData={defaultCompositions.productQuiz}
              ariaLabelPrev={defaultCompositions.siteSettings.ariaLabelPrev}
              ariaLabelNext={defaultCompositions.siteSettings.ariaLabelNext}
              lang={lang}
            />
          ) : null}

          <Link
            className="not-found-404-page__button"
            aria-label={goBackButton[0].properties.ariaLabel}
            to={goBackButton[0].properties?.link?.[0].url || '/'}
          >
            <IconCustom icon="arrow3-up" className="not-found-404-page__icon" />
            <span className="not-found-404-page__button-text">
              {goBackButton[0].properties.label}
            </span>
          </Link>
        </div>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($link: String!, $lang: String!) {
    pageData: notFound(link: { eq: $link }, lang: { eq: $lang }) {
      title
      image404 {
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      mobileImage404: image404 {
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 200, quality: 45) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      goBackButton {
        ...FragmentButton
      }
      subTitle
      startQuiz {
        ...FragmentStartQuizSection
      }
      seo {
        ...FragmentSeo
      }
      defaultCompositions {
        ...FragmentDefaultCompositions
      }
    }
  }
`;

export default NotFound404Page;
